
import fetchJsonp from "fetch-jsonp";
import md5 from 'js-md5';
export default {
    name: 'LoadingIP',
    data() {
        return {
            ipShow: true
        }
    },
    created() {
        if (process.client) {
            const search = location.search;
            if(search.indexOf('from=') > -1){
                this.ipShow = false;
            }else{
                this.checkIp();
            }
        }else{
            this.ipShow = false;

        }
    },
    methods: {
        async checkIp() {
            const _urlChoose = (data)=>{
                if(data[0] == '中国'){
                    if(data[1]=='台湾'){
                        window.location.replace("https://www.winindustry.com/tc/") 
                    }
                }else{
                    window.location.replace("https://www.win-industry.com/") 
                }
                this.ipShow = false;
            }
            if(localStorage&&localStorage.getItem('ipData')){
                let ipData = JSON.parse(localStorage.getItem('ipData')) ;
                _urlChoose(ipData)
                return;
            }
            let data = { ip: "",oid:"96257",mid:"149667",token:'1fad8d8a44f0a1dfa25172da6dbe1993' };
            const sign = md5("ip=" + data.ip + "&token=" + data.token);
            fetchJsonp("https://api.ip138.com/ipdata/?ip="+data.ip+"&oid=96257&mid=149667&sign="+sign,{
                jsonpCallback: 'callback',
            })
            .then(response => response.json() )
            .then(data => {
                if(data.ret == 'ok') {
                    localStorage.setItem('ipData',JSON.stringify(data.data));
                    _urlChoose(data.data)
                } else {
                    this.ipShow = false;
                }
                console.log(data)
            })
           
        }
    },
}
