
export default {
  props: {
    process:{
      type: Number,
      default: 0
    },
    act: {
      type: Boolean
    }
  },
  // watch: {
  //   process(){
  //   }
  // }
}

